import React from "react";
import { Link } from "gatsby";

export default function Thankyou() {
  return (
    <section className="relative text-center bg-white">
      <div
        className="flex items-center justify-center h-screen"
      >
        <div className="py-32 md:py-48 mt-8 max-w-5xl mx-auto px-4">
          <h1 className="text-3xl md:text-5xl text-black">
            Thankyou for your submission. A member of our team will be in touch shortly.
          </h1>
          <div className="pt-6">
            <Link className="text-black text-xl underline" to="/">
              Home
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
